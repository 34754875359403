<template>
  <div>
    <v-card-title class="text-overline pb-2 text-break"
      >Exprimez vos convictions</v-card-title
    >
    <v-container class="merchandising-list pt-0 px-0 px-sm-4" fluid>
      <v-card class="pa-1" flat color="transparent">
        <v-slide-group>
          <v-slide-item
            v-for="product in products"
            :key="product.title"
            class="d-flex flex-column align-center mx-2 py-1 merchandising-item overflow-hidden"
          >
            <v-hover>
              <template v-slot="{ hover }">
                <v-card
                  :color="hover ? 'secondary lighten-1' : 'transparent'"
                  flat
                  class="px-1"
                  :href="product.url"
                  target="_blank"
                  rel="noopener nofollow"
                >
                  <v-img
                    :src="product.image"
                    class="merchandising-image rounded-0"
                    :alt="product.title"
                    contain
                  ></v-img>
                  <v-list-item
                    three-line
                    class="px-0 pt-1"
                    style="min-height: 0"
                  >
                    <v-list-item-content class="py-0">
                      <v-list-item-subtitle
                        class="text-decoration-none text--secondary text-center font-weight-light"
                        style="font-size: 0.8rem"
                      >
                        {{ product.title }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
              </template>
            </v-hover>
          </v-slide-item>
        </v-slide-group>
      </v-card>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'ShopFeaturedList',
  data() {
    return {
      products: [
        {
          title: 'Poster en metal - Penser par soi-même',
          image: 'https://static.crowdbunker.com/think-by-ourselvers.jpg',
          url: 'https://go.cbk.re/nip',
        },
        {
          title: 'Poster en metal - Ignorance éclairée',
          image: 'https://static.crowdbunker.com/ignorance-éclairée.jpg',
          url: 'https://go.cbk.re/YoB',
        },
        {
          title: "Poster en metal - Vers l'infini...",
          image: 'https://static.crowdbunker.com/vers-infini.jpg',
          url: 'https://go.cbk.re/apY',
        },
        {
          title: 'T-shirt - Penser par soi-même',
          image:
            'https://static.crowdbunker.com/t-shirt-think-by-ourselves-fit.jpg',
          url: 'https://go.cbk.re/oaU',
        },
        {
          title: 'T-Shirt Man of the Year (noir)',
          image:
            'https://static.crowdbunker.com/t-shirt-man-of-the-year-black.jpg',
          url: 'https://go.cbk.re/keN',
        },
      ],
    }
  },
}
</script>

<style scoped>
.merchandising-image {
  width: 100px;
  height: 130px;
}
.text-decoration-none {
  text-decoration: none;
}
.merchandising-item {
  width: 106px;
}
</style>
