<template>
  <v-row>
    <v-col cols="auto">
      <p
        v-if="onUserProfile && $vuetify.breakpoint.smAndDown"
        class="custom-fs text-center my-0"
      >
        {{
          isConnect
            ? $tc('account.card-used-for-payment-in-organizations')
            : $tc('account.card-used-for-payment-in-crowdBunker')
        }}
      </p>
      <div
        style="max-width: 500px !important;"
        :class="
          checkPaymentMethodIsNull && onUserProfile
            ? 'row px-2 align-center'
            : ''
        "
      >
        <template>
          <template v-if="checkPaymentMethodIsNull && !onUserProfile">
            <div>{{ $tc('label.credit-card-payment') }} :</div>
            <credit-card-forms
              :forDonation="forDonation"
              :isConnect="isConnect"
            ></credit-card-forms>
          </template>
          <template v-else-if="checkPaymentMethodIsNull && onUserProfile">
            <v-col cols="8" sm="6" md="8" lg="10">
              <div>
                {{
                  isConnect
                    ? $tc(
                        'label.no-credit-card-payment-for-organizations-payment',
                      )
                    : $tc('label.no-credit-card-payment')
                }}
              </div>
            </v-col>
            <v-col cols="4" sm="6" md="4" lg="2">
              <v-subheader class="my-auto"
                ><a class="custom-fs" v-on:click="showPaymentFormDialog()">{{
                  $tc('label.add') | capitalize
                }}</a></v-subheader
              >
            </v-col>
          </template>
          <v-card v-else class="pa-0" color="transparent" flat>
            <template>
              <div v-if="!onUserProfile" class="mb-1 font-weight-bold">
                {{ $tc('label.credit-card-payment') }}
              </div>
              <div class="secondary">
                <v-card-title
                  class="pt-0 text-subtitle-1"
                  v-if="!onUserProfile"
                  >{{ $tc('label.saved-card') }}</v-card-title
                >
                <v-card-text :class="!onUserProfile ? '' : 'ps-5'">
                  <v-row
                    justify="center"
                    justify-md="space-between"
                    align="center"
                  >
                    <v-col
                      cols="auto"
                      :class="onUserProfile ? 'px-md-0 ps-lg-3' : ''"
                    >
                      <v-img
                        :src="getCardProviderLogo()"
                        alt="Card Provider"
                        class="card-logo"
                      />
                    </v-col>
                    <v-col cols="auto">
                      <div class="card-number text-no-wrap my-auto">
                        {{
                          `**** **** **** ${
                            isConnect
                              ? paymentMethodConnect.last4
                              : paymentMethod.last4
                          }`
                        }}
                      </div>
                    </v-col>
                    <v-col
                      cols="auto"
                      :class="onUserProfile ? 'px-md-0 ps-lg-3' : ''"
                    >
                      <div class="expiration my-auto">
                        {{
                          isConnect
                            ? paymentMethodConnect.expMonth
                            : paymentMethod.expMonth
                        }}
                        /
                        {{
                          isConnect
                            ? paymentMethodConnect.expYear
                            : paymentMethod.expYear
                        }}
                      </div>
                    </v-col>
                  </v-row>
                  <v-row
                    v-if="confirmCard"
                    justify="center"
                    justify-md="space-between"
                    align="center"
                  >
                    <credit-card-confirm-payment
                      :isConnect="isConnect"
                    ></credit-card-confirm-payment>
                  </v-row>
                </v-card-text>
                <div>
                  <v-btn
                    v-if="!showSmall"
                    id="change-card-btn"
                    @click="showPaymentFormDialog()"
                    color="primary"
                    text
                    class=""
                    >{{ $tc('account.change-credit-card') }}</v-btn
                  >
                  <v-btn
                    v-if="!showSmall"
                    id="remove-card-btn"
                    @click="removeCardConfirmationDialog = true"
                    color="grey"
                    depressed
                    rounded
                    text
                    class="text-caption"
                  >
                    {{ $tc('account.remove-credit-card') }}
                    <v-icon right>mdi-delete</v-icon>
                  </v-btn>
                </div>
              </div>
            </template>
          </v-card>
        </template>
        <!-- <p
      class="error--text mt-1"
      v-if="
        checkPaymentMethodIsNull &&
          stripeElementPaymentError.length > 0
      "
    >
      {{ stripeElementPaymentError }}
    </p> -->

        <v-dialog
          max-width="500"
          v-model="changingUserPaymentMethod"
          v-if="changingUserPaymentMethod"
          persistent
        >
          <v-card color="background">
            <v-toolbar dark color="secondary" flat dense>
              <v-toolbar-title>
                Changement de carte
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn :disabled="loadingPayment" icon dark @click="cancel()">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text>
              <div>
                <div class="my-1">
                  {{
                    (isConnect && paymentMethodConnect) ||
                    (!isConnect && paymentMethod)
                      ? 'Changer la carte de paiment par défaut :'
                      : 'Ajouter une nouvelle carte de paiment par défaut:'
                  }}
                </div>
                <credit-card-forms
                  :forDonation="forDonation"
                  :isConnect="isConnect"
                ></credit-card-forms>
              </div>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="cancel()">Annuler</v-btn>
              <v-btn
                color="success"
                @click="submitCard()"
                :loading="loadingPayment"
                depressed
                >{{ $t('button.save') }}</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="saveCardConfirmationDialog" width="400" persistent>
          <v-card>
            <v-card-title class="headline"
              >{{ $tc('label.confirmation') | capitalize }}
            </v-card-title>
            <v-card-text>
              {{
                $tc(
                  'account.would-you-register-credit-card-for-future-payment',
                ) | capitalize
              }}
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="confirmCancel">{{
                $tc('button.cancel')
              }}</v-btn>
              <v-btn text color="success" @click="confirmValid"
                >{{ $tc('button.confirm') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="removeCardConfirmationDialog" width="400" persistent>
          <v-card>
            <v-card-title class="headline"
              >{{ $tc('label.confirmation') | capitalize }}
            </v-card-title>
            <v-card-text>
              {{ $tc('account.confirm-delete-payment-method') | capitalize }}
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="confirmCancel" :disabled="loading">{{
                $tc('button.cancel')
              }}</v-btn>
              <v-btn
                text
                color="success"
                @click="removeUserPaymentMethod"
                :loading="loading"
                >{{ $tc('button.confirm') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </v-col>
    <v-col
      v-if="onUserProfile && $vuetify.breakpoint.smAndUp"
      class="d-flex align-center"
    >
      <v-divider vertical></v-divider>
      <p class="custom-fs ma-5">
        {{
          isConnect
            ? $tc('account.card-used-for-payment-in-organizations')
            : $tc('account.card-used-for-payment-in-crowdBunker')
        }}
      </p>
    </v-col>
  </v-row>
</template>

<script>
import CreditCardForms from '@/components/CreditCardForms'
import CreditCardConfirmPayment from '@/components/CreditCardConfirmPayment'
export default {
  name: 'CreditCardDetails',
  components: {
    CreditCardForms,
    CreditCardConfirmPayment,
  },
  props: {
    onUserProfile: {
      type: Boolean,
      default: false,
    },
    forDonation: {
      type: Boolean,
      default: false,
    },
    isConnect: {
      type: Boolean,
      default: false,
    },
    confirmCard: {
      type: Boolean,
      default: false,
    },
    showSmall: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      removeCardConfirmationDialog: false,
      loadingPayment: false,
      changingUserPaymentMethod: false,
      saveCardConfirmationDialog: false,
    }
  },
  computed: {
    paymentMethodConnect() {
      return this.$store.state.account.paymentMethodConnect
    },
    paymentMethod() {
      return this.$store.state.account.paymentMethod || null
    },
    stripeCard() {
      return this.$store.state.stripe.stripeCard
    },
    stripeElementPaymentError() {
      return this.$store.state.stripe.stripeElementPaymentError
    },
    checkPaymentMethodIsNull() {
      return this.isConnect
        ? this.paymentMethodConnect == null
        : this.paymentMethod == null
    },
  },
  methods: {
    getCardProviderLogo() {
      // Replace with logic to fetch and return the card provider logo URL based on the cardProvider prop
      // Example: with a visa logo urrl
      if (
        this.isConnect
          ? this.paymentMethodConnect.brand === 'visa'
          : this.paymentMethod.brand === 'visa'
      )
        return 'https://upload.wikimedia.org/wikipedia/commons/thumb/d/d6/Visa_2021.svg/1920px-Visa_2021.svg.png'
      if (
        this.isConnect
          ? this.paymentMethodConnect.brand === 'mastercard'
          : this.paymentMethod.brand === 'mastercard'
      )
        return 'https://upload.wikimedia.org/wikipedia/commons/thumb/a/a4/Mastercard_2019_logo.svg/1920px-Mastercard_2019_logo.svg.png'
      else return '/images/debit-card.jpg'
      // return '/path/to/default-logo.png'
    },
    showPaymentFormDialog() {
      this.changingUserPaymentMethod = true
    },
    cancel() {
      this.changingUserPaymentMethod = false
      this.loadingPayment = false
    },
    confirmCancel() {
      this.saveCardConfirmationDialog = false
      this.removeCardConfirmationDialog = false
    },
    confirmValid() {
      this.saveCardConfirmationDialog = false
      this.saveUserPaymentMethodInfos({
        paymentMethodId: this.paymentMethod.id,
        last4: this.paymentMethod.last4,
        brand: this.paymentMethod.brand,
        expMonth: this.paymentMethod.expMonth,
        expYear: this.paymentMethod.expYear,
        country: this.paymentMethod.country,
        paymentMethodType: this.paymentMethod.paymentMethodType,
        isConnect: this.isConnect,
      })
    },
    submitCard() {
      if (this.paymentMethod === null && !this.stripeCard._complete) {
        this.$store.dispatch(
          'stripe/setStripeElementPaymentError',
          this.$tc('error.payment-data-incomplete'),
        )
        return
      }
      this.loadingPayment = true

      this.$store
        .dispatch('stripe/stripeCreatePaymentMethod', {
          email: this.$store.state.account.user.user.email,
        })
        .then(result => {
          this.saveUserPaymentMethodInfos({
            paymentMethodId: result.paymentMethod.id,
            last4: result.paymentMethod.card.last4,
            brand: result.paymentMethod.card.brand,
            expMonth: result.paymentMethod.card.exp_month,
            expYear: result.paymentMethod.card.exp_year,
            country: result.paymentMethod.card.country,
            paymentMethodType: result.paymentMethod.type,
            isConnect: this.isConnect,
          })
        })
        .catch(err => {
          this.stripeElementPaymentError = err.message
          this.loadingPayment = false
        })
    },
    saveUserPaymentMethodInfos({
      paymentMethodId,
      last4,
      brand,
      expMonth,
      expYear,
      country,
      paymentMethodType,
      isConnect,
    }) {
      this.$store
        .dispatch('account/savePaymentMethod', {
          paymentMethodId,
          last4,
          brand,
          country,
          expMonth,
          expYear,
          paymentMethodType,
          isConnect,
        })
        .then(() => {
          this.cancel()
        })
        .catch(err => {
          this.$store.dispatch('stripe/setStripeElementPaymentError', err)
        })
        .finally(() => {
          this.loadingPayment = false
        })
    },
    removeUserPaymentMethod() {
      this.loading = true
      this.$store
        .dispatch('account/removePaymentMethod', {
          paymentMethodId: this.isConnect
            ? this.paymentMethodConnect.id
            : this.paymentMethod.id,
          isConnect: this.isConnect,
        })
        .then(() => {
          this.loading = false
          this.confirmCancel()
          this.$store.dispatch(
            'alert/success',
            this.$tc('account.payment-method-has-been-successfully-deleted'),
          )
        })
        .catch(err => {
          this.loading = false
          this.confirmCancel()
          this.$store.dispatch('alert/error', err)
        })
    },
  },
}
</script>

<style scoped>
@media (min-width: 640px) {
  .custom-fs {
    font-size: 1.2em;
  }
}
@media (min-width: 540px) {
  .custom-p {
    position: relative;
  }
}
.card-logo {
  max-width: 40px;
}
.card-number {
  font-size: 16px;
}
.card-holder {
  font-size: 16px;
  margin-top: 10px;
}
.expiration {
  font-size: 16px;
}
</style>
